body {
  margin: 0;
  padding: 0;
  background-color: white; /* Set the background color to white */
}

.RootContainer {
  /* background-color: #c1c6cf; */
  min-height: 100vh;
  display: flex;
  
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);

  color: hsl(216, 16%, 50%);
}

.ContentContainer {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 32pt;
  max-width: 800px;
}

.Header, .Subheader, .Link {
  width: 100%;

  font-weight: 500;
  color: rgb(255, 255, 255, 0.9);

  color: hsl(216, 10%, 30%);

  font-size: 16pt;
  letter-spacing: .015em;
  line-height: 1.5em;
}

.Header {
  margin-top:  32pt;
  margin-bottom: 10pt;
}

.Subheader {
  /*margin-top: 10pt;*/
  font-size: 12pt;
  line-height: 1.7em;

  color: hsl(216, 10%, 50%);
}

.Link {
  margin-top: -10pt;
  margin-bottom: 16pt;
  font-size: 10pt;

  color: hsl(216, 10%, 50%);

  text-align: right;
}

.Link a {
  margin-right: 10pt;
}



.TabBar {
  width: 100%;
  /* background-color: rgba(0, 174, 255, 0.1); */
  
  /* margin-top: 20pt; */

  /* justify-content: right; */
  display: flex;

  /* margin-left: -6pt; */
}

.SmallCaps {
  font-variant: small-caps;
  font-weight: 500;
  font-size: 12pt;

  margin-top: 6pt;
  letter-spacing: .015em;
}

a {
  color: hsl(216, 16%, 50%);
  text-underline-offset: 4pt;

  color: hsl(216, 90%, 55%);
  text-decoration: none;

  letter-spacing: .015em;
}

h2, h3, h4 {
  color: hsl(216, 16%, 50%);
  font-size: 16pt;
  font-weight: 500;
  letter-spacing: .015em;
}

h3 {
  font-size: 14pt;
  font-variant: small-caps;
  padding: 0;
  margin: 0;
}

.SectionHeader {
  margin-top: 20pt;
}

h4 {
  font-size: 12pt;
}


.WorkHistory {
  margin-top: 20pt;
}

.Logo {
  /* width: 50pt;
  max-width: 50pt; */
}

.Logo img {
  width: 22pt;
  margin-right: 6pt;
}

.image-txt-container {
  /* background-color: blue; */

  color: hsl(216, 16%, 60%);

  display: flex;
  align-items: center;

  flex-direction: row;

  font-size: 14pt;
  font-weight: 500;

  margin-bottom: 6pt;
}

.TabItem {
    border-radius: 20pt;
    border-color: blue;

    margin-right: 8pt;
    padding: 8pt 12pt 8pt 12pt;

    font-size: 12pt;

    font-weight: 500;

    transition: all .15s ease-in-out;

    letter-spacing: .015em;
}

.TabItem.Selected {
  background-color: hsl(216, 100%, 95%);
  font-weight: 600;
}

.TabItem:hover {
  background-color: hsl(216, 10%, 95%);
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100;
}

.ProjectContainer {
  margin-top: 24pt;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;

  padding: 10pt;
}

.column:first-child {
  padding-left: 0;
}

.column:last-child {
  padding-right: 0;
}

.column:hover {
  /* transform: scale(1.02); */
}

.blue-column {
  
  border-radius: 10pt;
  overflow: hidden;

  transition: all 0.2s ease-in-out;

  transition: opacity 0.5s ease-in-out;
}

.blue-column.ready {
  opacity: 1;
}

.blue-column:hover {
  /*transform: scale(1.04);*/

/*   box-shadow:
  0 1px 1px hsl(0deg 0% 0% / 0.075),
  0 2px 2px hsl(0deg 0% 0% / 0.075),
  0 4px 4px hsl(0deg 0% 0% / 0.075),
  0 8px 8px hsl(0deg 0% 0% / 0.075),
  0 16px 16px hsl(0deg 0% 0% / 0.075); */
}

video {
  object-fit: cover;
  border-bottom: 1px solid transparent;
}


video::-webkit-media-controls-start-playback-button {
  display: none;
  /*pointer-events: none;*/

}

video::-webkit-media-controls {
  display:none !important;
}

/*.video-hidden {
  display: none;
}*/

.CardContainer {

  display: grid;
  justify-content: center;
  grid-template-columns: 100pt 100pt;
  transform: translate(-50pt, 0pt);
  /* grid-template-columns: 20%; */

  background-color: purple;

}



* {
    font-family: -apple-system, system-ui, BlinkMacSystemFont;
}

